@import "../../../assets/styles/variables";

.page-top-background {
    position: absolute;
    left: 50%;
    right: 0;
    width: $content-width + $padding-regular + $padding-regular;
    z-index: 0;
    transform: translateX(-50%);

    img {
        width: 100%;
    }
}
