@import "../../../assets/styles/colors";

.header {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;

    &__content {
        display: flex;
        align-items: stretch;
        height: 64px;
    }

    &__logo {
        width: 185px;

        a {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }

    &__nav {
        display: flex;
        margin-left: 40px;
        margin-right: auto;

        a {
            display: flex;
            align-items: center;
            height: 100%;
        }

        &-link {
            margin-right: 24px;

            &.active {
                box-shadow: inset 0px -2px 0px #ffdd2d;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__currency {
        position: relative;
        display: flex;
        align-items: center;
        color: $color-b1;
        font-weight: 400;
        padding-right: 15px;
        margin-right: 4px;

        span {
            color: $color-m6;
            margin: 0 8px;
        }

        &:after {
            content: "";
            position: absolute;
            top: 16px;
            bottom: 16px;
            right: 0;
            width: 1px;
            background: $color-l8;
        }
    }

    &__notification {
        display: flex;
        align-items: center;
        padding: 0 10px;
        margin-right: 8px;

        &-svg {
            position: relative;

            &-num {
                position: absolute;
                top: -7px;
                right: -7px;
                width: 14px;
                height: 14px;
                border-radius: 14px;
                color: #ffffff;
                font-size: 10px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $color-red;
            }
        }
    }

    &__user {
        $parent-user: &;
        position: relative;
        display: flex;
        align-items: center;

        &-link {
            display: flex;
            align-items: center;

            &-icon {
                display: flex;
                margin-right: 10px;

                svg {
                    font-size: 24px !important;
                }
            }

            &-text {
                color: $color-b1;

                &-name {
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 150px;
                    white-space: nowrap;

                    &.size-large {
                        font-size: 12px;
                    }
                }

                &-inn {
                    margin-top: 4px;
                    font-weight: 300;
                    font-size: 10px;
                    line-height: 10px;
                    color: $color-m4;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 150px;
                    white-space: nowrap;
                }
            }
        }

        &:hover {
            #{$parent-user}-menu {
                display: block;
            }
        }

        &-menu {
            display: none;
            position: absolute;
            top: 100%;
            right: 0;

            &-list {
                margin-top: 8px;
                background: #ffffff;
                padding: 12px 0;
                box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
                border-radius: 8px;

                &-item {
                    display: block;
                    white-space: nowrap;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 24px;
                    color: $color-b1;
                    padding: 12px 24px;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        &-non-auth {
            display: flex;
            align-items: center;
            margin-left: 8px;

            svg {
                margin-right: 8px;
            }
        }
    }
}

.m-header {
    display: flex;
    height: 56px;
    //padding: 0 16px;
    background-color: #ffffff;
    box-shadow: inset 0px -1px 0px #e0e0e0;

    &__burger {
        display: flex;
        align-items: center;
        padding: 0 16px;
    }

    &__logo {
        display: flex;
        align-items: center;
        width: 140px;
    }

    &__user {
        display: flex;
        align-items: center;
        margin-left: auto;

        &-link {
            display: flex;
            align-items: center;
            padding-right: 16px;
            font-size: 14px;
            font-weight: 500;

            .MuiSvgIcon-root {
                margin-right: 6px;
            }

            span {
                max-width: calc(100vw - 250px);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}
