@import "../../../assets/styles/colors";

.m-menu {
    position: absolute;
    top: 56px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 56px);
    padding: 16px;
    background-color: #f4f5f7;
    box-sizing: border-box;
    overflow-y: auto;
    z-index: 10;

    transform: translateX(-100%);
    transition: transform 0.2s ease;

    &_open {
        transform: translateX(0);
    }

    &__pages {
        margin-bottom: 24px;
    }

    &__link {
        display: flex;
        align-items: center;
        padding: 16px;
        background-color: #ffffff;
        border: 1px solid $color-l7;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 18px;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }

        &-arrow.MuiSvgIcon-root {
            font-size: 16px;
            margin-left: auto;
        }
    }

    &__cabinet {
        margin-bottom: 36px;

        &-title {
            font-size: 14px;
            font-weight: 600;
            color: $color-m5;
            margin-bottom: 8px;
        }

        &-links {
            display: flex;
            flex-direction: column;
            border: 1px solid $color-l7;
            box-sizing: border-box;
            border-radius: 8px;
            background: #ffffff;

            &-item {
                display: flex;
                align-items: center;
                height: 48px;
                padding: 0 16px;
                box-shadow: inset 0px -1px 0px $color-l7;
                font-weight: 600;
                font-size: 14px;
                color: $color-b1;

                &:last-child {
                    box-shadow: none;
                }
            }
        }
    }

    &__policy {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 0;

        &-item {
            color: $color-m4;
            font-size: 12px;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
