$color-black: #000000;
$color-b1: #0b1622;
$color-b2: #27323e;
$color-b3: #444f5a;
$color-m4: #717e8a;
$color-m5: #93a0ac;
$color-m6: #b5c2cf;
$color-l7: #e3edf4;
$color-l8: #ebf1f8;
$color-l9: #f4f8fc;

$color-red: #ff2c2c;
$color-error-border: $color-red;
$color-error-bg: #f7eeee;
//$color-yellow: #ffdd2d;
$color-yellow: #ffec8a;
$color-yellow-dark: #ffc820;
$color-yellow-hover: #ffb400;
$color-yellow-focus: #ffc53a;
$color-yellow-active: #ffc53a;
$color-green: #15b839;
$color-green-2: #27ae60;
$color-orange: #ff7a00;
$color-grey: #f4f5f7;
$color-grey-3: #828282;

$color-blue: #5daae8;
$color-main: #5daae8;
