.page {
    //

    &__btn-back.custom-btn {
        position: absolute;
        top: 16px;
        left: 0;
    }
}

.is-mobile {
    .page {
        //

        &__btn-back.custom-btn {
            background: none;
            //box-shadow: none;
        }
    }
}
