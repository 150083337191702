@import "./colors";
@import "./variables";

* {
    font-family: "Inter", "system-ui", sans-serif !important;
    //color: $color-b1;
}

*,
::after,
::before {
    box-sizing: border-box;
}

body {
    //
}

.content {
    width: 100%;
    max-width: $content-width;
    margin: 0 auto;
    //overflow: hidden;
}

.page {
    //padding-top: 48px;
}

.is-mobile {
    //

    .content {
        //max-width: calc(100% - 16px);
        width: calc(100% - 16px);
        max-width: 460px;
    }
}
