@import "../../../assets/styles/variables";
@import "../../../assets/styles/colors";

.not-found {
    max-width: $content-width;
    margin: 0 auto;

    &--gradient {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: -1px;
        background: conic-gradient(from 169.32deg at 107.61% 54.63%, rgba(170, 254, 194, 0) 0deg, #B5FDA9 18.03deg, rgba(170, 254, 208, 0) 104.03deg, rgba(170, 254, 194, 0) 360deg), radial-gradient(38.17% 86.77% at 10.46% 79.54%, #FFEEC3 0%, rgba(255, 238, 195, 0) 100%), radial-gradient(61.14% 73.94% at 44.57% -39.19%, #9ED2FF 0%, rgba(158, 210, 255, 0) 100%), #FFFFFF;
        opacity: 0.5;
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 100px;
        z-index: 1;
        padding-top: 164px;
    }

    &__img {
        position: relative;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 48%;
        margin-bottom: 24px;

        img {
            position: relative;
            max-width: 100%;
            z-index: 1;
        }
    }

    &__h1 {
        font-weight: 700;
        font-size: 48px;
        line-height: 58px;
        color: $color-black;
        margin-bottom: 16px;

        &--mobile {
            font-size: 26px;
            line-height: 31px;
        }
    }

    &__h2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: $color-black;
        margin-bottom: 24px;

        &--mobile {
            font-size: 18px;
            line-height: 28px;
        }
    }

    &__home-btn {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 12px;
    }
}