@import "../../../assets/styles/colors";

.MuiButton-root.custom-btn {
    border-radius: 8px;
    font-size: 14px;
    font-weight: 300;
    text-transform: none;
    padding: 16px 24px;

    .custom-btn {
        //

        &__content {
            position: relative;
        }

        &__loading {
            opacity: 0;
            width: 24px;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-self: center;
            margin: auto;
            pointer-events: none;
        }
    }

    &.custom-btn_loading {
        //

        .custom-btn__loading {
            opacity: 1;
        }

        .custom-btn__content {
            & > div:nth-child(1) {
                opacity: 0;
            }
        }

        .MuiSvgIcon-root {
            display: none;
        }
    }

    &.custom-btn_small {
        font-size: 13px;
        font-weight: 300;
        padding: 9px 16px;

        .custom-svg {
            font-size: 14px;
        }
    }

    &.custom-btn_medium {
        font-size: 14px;
        font-weight: 500;
        padding: 12px 24px 12px 24px;

        .custom-svg {
            font-size: 14px;
        }
    }

    &.custom-btn_large {
        font-size: 16px;
        //font-weight: 500;
        padding: 18px 24px;

        .custom-svg {
            font-size: 24px;
        }
    }

    &.custom-btn_white {
        background-color: #ffffff;
        color: $color-b1;
        box-shadow: inset 0 0 0 1px $color-l7;

        &:hover {
            background: #f5f5f5;
        }

        &:active {
            background-color: #f5f5f5;
            box-shadow: inset 0 0 0 2px #f5f5f5;
        }

        .MuiTouchRipple-rippleVisible {
            color: #cccccc;
        }
    }

    &.custom-btn_red {
        background-color: #ffffff;
        color: $color-red;
        box-shadow: inset 0 0 0 1px $color-red;

        &:hover {
            background: rgba($color-red, 0.1);
        }

        &:active {
            background-color: rgba($color-red, 0.2);
            box-shadow: inset 0 0 0 2px rgba($color-red, 0.5);
        }

        .MuiTouchRipple-rippleVisible {
            color: rgba($color-red, 0.3);
        }
    }

    &.custom-btn_blue {
        background-color: $color-l9;
        color: $color-main;
        box-shadow: inset 0 0 0 1px #e3edf4;
        //font-weight: 600;

        &:hover {
            background: $color-l8;
        }

        &:active {
            background-color: $color-l7;
            box-shadow: inset 0 0 0 2px #e3edf4;
        }

        .MuiTouchRipple-rippleVisible {
            color: rgba($color-main, 0.3);
        }
    }

    .MuiTouchRipple-rippleVisible {
        color: #ffffff;
    }

    &_main {
        background-color: $color-yellow;
        color: $color-b1;

        &:hover {
            background-color: $color-yellow-hover;
        }

        &:active {
            background-color: $color-yellow-active;
            box-shadow: inset 0 0 0 2px rgba(231, 151, 8, 0.6);
        }
    }
}
