@import "../../../assets/styles/colors";

.payment-page-error {
    position: relative;
    color: $color-b1;

    .content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 100px;
        z-index: 1;
    }

    &__btn-back.MuiButtonBase-root {
        position: absolute;
        top: 16px;
        left: 0;
    }

    &__title {
        font-weight: 600;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        margin-bottom: 38px;
    }

    &__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% - 16px);
        max-width: 688px;
        background: #ffffff;
        box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        padding: 32px;

        &-icon {
            margin-bottom: 32px;
        }

        &-title {
            font-weight: 500;
            font-size: 24px;
            line-height: 26px;
            margin-bottom: 16px;
            text-align: center;
        }

        &-description {
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            margin-bottom: 32px;
        }

        &-btn {
            //
        }
    }
}
