.topMobileBackHeader {
    background: #ffffff;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 2;

    .backButton {
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .headerTitle {
        margin-left: -56px;
        font-weight: 700;
        font-size: 13px;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
    }
}
