.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 64px;
    box-sizing: border-box;

    &__header {
        position: fixed;
        top: 0;
        width: 100%;
        background-color: #ffffff;
        box-shadow: inset 0px -1px 0px #e0e0e0;
        z-index: 100;
    }

    &__content {
        flex-grow: 1;
        overflow: hidden;

        &.withoutOverflow {
            overflow: inherit;
        }
    }
}

.is-mobile.layout {
    padding-top: 56px;
}
